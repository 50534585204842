import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { position } from 'styled-system';
import { theme as Rtheme } from '../Utils/theme';
import { ErrorUI } from '../Molecules/ErrorUI';

const HiddenInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

const ImageWrapper = styled.div`
  position: ${props => props.position};
  height: ${props => props.height};
  width: ${props => props.width};
`;

const EditMask = styled.div`
  position: ${props => props.position};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius};
  opacity: 0.3;
  background-color: ${Rtheme.colors.black};
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  ${position}
`;

const LogoText = styled.p`
  color: #fff;
  margin: 0 8px;
`;

const IconCamera = styled.span`
  display: block;
  font-size: 18px;
  color: ${Rtheme.colors.white};
`;

const Image = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius};
`;

export function ImageUploader({
  id,
  input: { value, onChange },
  allowedFormats,
  borderRadius,
  width,
  height,
  caption,
  position,
  zIndex,
  meta,
}) {
  const handleChange = () => {
    const input = document.getElementById(id);
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function onLoad(e) {
        onChange(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <ImageWrapper width={width} height={height} position={position}>
        {!value && (
          <EditMask
            position={position}
            borderRadius={borderRadius}
            zIndex={zIndex}
          >
            <IconCamera className="material-icons-outlined">
              camera_alt
            </IconCamera>
            {caption && <LogoText>{caption}</LogoText>}
          </EditMask>
        )}
        <HiddenInput id={id} onChange={handleChange} accept={allowedFormats} />
        {value && (
          <Image
            id="account-image"
            borderRadius={borderRadius}
            alt=""
            src={value}
            role="presentation"
          />
        )}
      </ImageWrapper>
      <ErrorUI meta={meta} />
    </label>
  );
}

ImageUploader.defaultProps = {
  id: undefined,
  input: { onChange: () => {} },
  allowedFormats: undefined,
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  caption: undefined,
  zIndex: '1',
  position: 'relative',
  meta: { error: undefined, touched: undefined, warning: undefined },
};

ImageUploader.propTypes = {
  id: PropTypes.string,
  allowedFormats: PropTypes.string,
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  borderRadius: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  caption: PropTypes.string,
  position: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
