import React from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

export default function PhoneBreakpoint({ showForDesktop, children }) {
  return (
    <MediaQuery
      maxDeviceWidth={599}
      query={showForDesktop && '(max-width: 599px)'}
    >
      {children}
    </MediaQuery>
  );
}
PhoneBreakpoint.defaultProps = {
  children: undefined,
  showForDesktop: false,
};

PhoneBreakpoint.propTypes = {
  children: PropTypes.object,
  showForDesktop: PropTypes.bool,
};
