import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../Atoms';
import GoogleActive from '../../Icons/googleCalendarActive.svg';
import GoogleInActive from '../../Icons/googleCalendarInactive.svg';
import OutlookActive from '../../Icons/microsoftOutlookCalendarActive.svg';
import OutlookInActive from '../../Icons/microsoftOutlookCalendarInactive.svg';
import userDefault from '../../Icons/userDefault.png';
import { theme as Rtheme } from '../Utils/theme';

const ListItem = styled.div`
  position: relative;
  padding: 8px;
  border-bottom: 1px solid ${Rtheme.colors.borderColorLight};
  -webkit-animation: bcCCNc 300ms ease;
  animation: bcCCNc 300ms ease;
  border-radius: 3px;
`;

const ItemImage = styled.img`
  float: left;
  width: 40px;
  border-radius: 50%;
`;
const LabelWrapper = styled.div`
  width: 200px;
  float: left;
  padding-left: 18px;
  flex-basis: 200px;
`;

const ItemText = styled.p`
  color: ${Rtheme.colors.fontColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 40px;
`;

const DivWrapper = styled.div`
  float: left;
  line-height: 40px;
  padding-left: 18px;
`;

const SVGImage = styled.img`
  vertical-align: middle;
`;

const AddButton = styled(Button)`
  min-width: 80px;
  height: 40px;
`;

const OutlinedButton = styled(AddButton)`
  color: ${Rtheme.colors.fontColorSecondary};
  border-color: ${Rtheme.colors.fontColorSecondary};
`;

export function MemberListItem({
  id,
  iconUri,
  label,
  selected,
  onRemoveSelectedEmployee,
  authorizedSchedulers,
  onClick,
}) {
  return (
    <ListItem key={id}>
      <ItemImage src={iconUri || userDefault} />
      <LabelWrapper>
        <ItemText>{label}</ItemText>
      </LabelWrapper>
      <DivWrapper>
        <SVGImage
          src={authorizedSchedulers?.google ? GoogleActive : GoogleInActive}
        />
        <SVGImage
          src={authorizedSchedulers?.outlook ? OutlookActive : OutlookInActive}
        />
      </DivWrapper>
      <DivWrapper>
        {selected && (
          <OutlinedButton outlined onClick={onRemoveSelectedEmployee}>
            Added
          </OutlinedButton>
        )}
        {!selected && <AddButton onClick={onClick}>Add</AddButton>}
      </DivWrapper>
    </ListItem>
  );
}

MemberListItem.defaultProps = {
  id: undefined,
  iconUri: undefined,
  label: 'Name',
  selected: false,
  authorizedSchedulers: { google: true, outlook: false },
  onClick: () => {},
  onRemoveSelectedEmployee: () => {},
};

MemberListItem.propTypes = {
  id: PropTypes.string,
  iconUri: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.bool,
  authorizedSchedulers: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func,
  onRemoveSelectedEmployee: PropTypes.func,
};

export default MemberListItem;
