import React, { useState } from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import { theme as Rtheme } from '../Utils/theme';

const Icon = styled(FontAwesome)`
  width: 13px;
  height: 13px;
  position: relative;
  top: 1px;
  margin-left: -2px;
`;

const NewNotifications = styled.span`
  position: fixed;
  padding: 3px 3px;
  border-radius: 50%;
  border: solid 2px white;
  background: red;
`;

const DropDownButton = styled.li`
  display: inline-block;
  margin-left: 20px;
  box-sizing: border-box;
  padding: 10px 0;
  cursor: pointer;
`;

const DropDownContent = styled.div`
  display: ${props => (props.showDropdownOptions ? 'block' : 'none')};
  position: absolute;
  right: ${props => props.right};
  margin-top: 25px;
  border-radius: 4px;
  border: 1px solid ${Rtheme.colors.border};
  background-color: white;
  box-shadow: 0 8px 16px 0 ${Rtheme.colors.boxShadow};
  z-index: 2;
  width: max-content;
  left: -80px;
  padding: 19px;
`;

export function NotificationButton({ items, active }) {
  const [toggle, setToggle] = useState(false);

  const toggleNotifications = () => {
    setToggle(!toggle);
  };

  window.onclick = function onclick(event) {
    if (
      !event.target.matches('#notificationButton') &&
      !event.target.matches('#newNotifications')
    ) {
      setToggle(false);
    }
  };

  return (
    <DropDownButton onClick={toggleNotifications}>
      <Icon name="fas fa-bell" id="notificationButton" />
      {active && <NewNotifications id="newNotifications" />}
      {items.length > 0 && (
        <DropDownContent showDropdownOptions={toggle}>{items}</DropDownContent>
      )}
    </DropDownButton>
  );
}

NotificationButton.defaultProps = {
  active: false,
  items: [],
};

NotificationButton.propTypes = {
  active: PropTypes.bool,
  items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};
