import React from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

export default function DesktopBreakpoint({ hideForDesktop, children }) {
  return (
    <MediaQuery
      minDeviceWidth={600}
      query={hideForDesktop && '(min-width: 600px)'}
    >
      {children}
    </MediaQuery>
  );
}

DesktopBreakpoint.defaultProps = {
  children: undefined,
  hideForDesktop: false,
};

DesktopBreakpoint.propTypes = {
  children: PropTypes.object,
  hideForDesktop: PropTypes.bool,
};
