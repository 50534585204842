import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { minWidth } from 'styled-system';
import { InputSelectWithButton } from './InputSelectWithButton';
import { Button, Checkbox, Icon } from '../Atoms';
import { Col } from '../Utils/Grid';
import { theme as Rtheme } from '../Utils/theme';

const SelectorWrapper = styled(Col)`
  min-width: ${props => props.minWidth};
  justify-content: normal;
  flex: 0 0 auto;
  display: flex;
  ${minWidth}
  ${Button} {
    &:before {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const DropDownButton = styled.li`
  float: left;
  position: relative;
  display: inline-block;
`;

const DropDownContent = styled.div`
  display: ${props => (props.showDropdownOptions ? 'block' : 'none')};
  position: absolute;
  right: ${props => props.right};
  margin-top: 2px;
  border-radius: 4px;
  border: 1px solid ${Rtheme.colors.border};
  background-color: white;
  box-shadow: 0 8px 16px 0 ${Rtheme.colors.boxShadow};
  z-index: 2;
`;

const StyledHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${Rtheme.colors.border};
  width: 90%;
`;

const DropDownItem = styled.a`
  color: ${Rtheme.colors.fontColor};
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${props => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${Rtheme.colors.bgActive};
  }
`;

const DropDownCheckboxItem = styled.div`
  padding: 2px 16px;
`;

export function InputSelectWithButtonAndDropdown({
  height,
  wrapperMinWidth,
  inputSelectButtonLabel,
  inputSelectWidth,
  inputSelectButtonMinWidth,
  fontSize,
  fontWeight,
  inputSelectOptions,
  inputSelectValue,
  inputSelectOnChange,
  showCopiedTooltip,
  tooltipText,
  showDropdownOptions,
  isDisabled,
  removeRightBorder,
  inputSelectOnClick,
  dropdownBtnId,
  dropdownBtnMarginRight,
  dropdownBtnPadding,
  dropdownBtnWidth,
  dropdownBtnMinWidth,
  dropdownBtnBorderRadius,
  dropdownBtnIconSize,
  dropdownBtnIconColor,
  dropdownBtnOptions,
  dropdownContentWidth,
  dropdownContentRight,
  dropdownCheckboxItems,
  dropdownBtnOnClick,
  isMobileView,
}) {
  return (
    <SelectorWrapper
      display="flex"
      justifyContent="flex-end"
      minWidth={wrapperMinWidth}
    >
      <InputSelectWithButton
        height={height}
        buttonLabel={inputSelectButtonLabel}
        width={inputSelectWidth}
        buttonMinWidth={inputSelectButtonMinWidth}
        outlined
        fontSize={fontSize}
        fontWeight={fontWeight}
        options={inputSelectOptions}
        value={inputSelectValue}
        onChange={inputSelectOnChange}
        showCopiedTooltip={showCopiedTooltip}
        tooltipText={tooltipText}
        disabled={isDisabled}
        removeRightBorder={removeRightBorder}
        onClick={inputSelectOnClick}
        isMobileView={isMobileView}
      />
      <DropDownButton>
        <Button
          id={dropdownBtnId}
          mr={dropdownBtnMarginRight}
          p={dropdownBtnPadding}
          width={dropdownBtnWidth}
          minWidth={dropdownBtnMinWidth}
          height={height}
          borderRadius={dropdownBtnBorderRadius}
          isIconPresent
          iconContent="expand_more"
          iconFontFamily="Material Icons"
          iconSize={dropdownBtnIconSize}
          iconColor={dropdownBtnIconColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          outlined
          disabled={isDisabled}
          onClick={() => dropdownBtnOnClick('showList')}
        />
        <DropDownContent
          showDropdownOptions={showDropdownOptions}
          right={dropdownContentRight}
        >
          {dropdownBtnOptions.map(option => (
            <DropDownItem
              width={dropdownContentWidth}
              key={option.value}
              onClick={() => dropdownBtnOnClick(option)}
            >
              {option.label}
            </DropDownItem>
          ))}
          <StyledHr />
          {dropdownCheckboxItems.map(item => (
            <DropDownCheckboxItem key={item.id} id={item.id}>
              <Checkbox
                labelWeight="normal"
                useArrayValue={false}
                input={{
                  value: item.value,
                  onChange: value => item.onChange(value),
                }}
              >
                {item.label}
              </Checkbox>
            </DropDownCheckboxItem>
          ))}
        </DropDownContent>
      </DropDownButton>
    </SelectorWrapper>
  );
}

InputSelectWithButtonAndDropdown.defaultProps = {
  height: '44px',
  wrapperMinWidth: '370px',
  dropdownContentRight: '20px',
  dropdownBtnOptions: [
    {
      value: 'Option 1',
      label: 'Option 1',
    },
    {
      value: 'Option 2',
      label: 'Option 2',
    },
  ],
  removeRightBorder: true,
  fontSize: '14px',
  fontWeight: '500',
  inputSelectOptions: [
    {
      value: 'Option 1',
      label: 'Option 1',
    },
    {
      value: 'Option 2',
      label: 'Option 2',
    },
  ],
  inputSelectValue: {
    value: 'Option 1',
    label: 'Option 1',
  },
  inputSelectButtonMinWidth: '200px',
  inputSelectWidth: '400px',
  dropdownContentWidth: '220px',
  showCopiedTooltip: true,
  isDisabled: false,
  inputSelectButtonLabel: (
    <div style={{ paddingLeft: '10px' }}>
      <Icon justifyContent="flex-start" text="Option 1" />
    </div>
  ),
  showDropdownOptions: false,
  dropdownBtnId: 'dropDownBtn',
  dropdownBtnMarginRight: '20px',
  dropdownBtnPadding: '0 30px 0 30px',
  dropdownBtnWidth: '10px',
  dropdownBtnMinWidth: '0',
  dropdownBtnBorderRadius: '0 5px 5px 0',
  dropdownBtnIconSize: '20px',
  dropdownBtnIconColor: '#00bbb5',
  dropdownCheckboxItems: [
    {
      id: 'checkbox-1',
      value: false,
      onChange: () => {},
      label: 'Checkbox',
    },
  ],
  inputSelectOnChange: () => {},
  inputSelectOnClick: () => {},
  dropdownBtnOnClick: () => {},
  tooltipText: undefined,
};

InputSelectWithButtonAndDropdown.propTypes = {
  height: PropTypes.string,
  wrapperMinWidth: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dropdownContentRight: PropTypes.string,
  dropdownBtnOptions: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  removeRightBorder: PropTypes.bool,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  inputSelectOptions: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  inputSelectValue: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  inputSelectButtonMinWidth: PropTypes.string,
  inputSelectWidth: PropTypes.string,
  dropdownContentWidth: PropTypes.string,
  showCopiedTooltip: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  inputSelectButtonLabel: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  showDropdownOptions: PropTypes.bool,
  dropdownBtnId: PropTypes.string,
  dropdownBtnMarginRight: PropTypes.string,
  dropdownBtnPadding: PropTypes.string,
  dropdownBtnWidth: PropTypes.string,
  dropdownBtnMinWidth: PropTypes.string,
  dropdownBtnBorderRadius: PropTypes.string,
  dropdownBtnIconSize: PropTypes.string,
  dropdownBtnIconColor: PropTypes.string,
  dropdownCheckboxItems: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  inputSelectOnChange: PropTypes.func,
  inputSelectOnClick: PropTypes.func,
  dropdownBtnOnClick: PropTypes.func,
};
