import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margin, borderRadius, height } from 'styled-system';
import { ErrorUI } from '../Molecules/ErrorUI';
import { theme as Rtheme } from '../Utils/theme';
import { generateTag } from '../Utils/test';

const Input = styled.input`
  box-sizing: border-box;
  ${height};
  ${borderRadius};
  border: solid 1px ${props => props.borderColor || Rtheme.colors.borderColor};
  background-color: ${Rtheme.colors.white};
  ${margin};
  margin-bottom: 5px;
  display: block;

  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: ${Rtheme.colors.fontColor};
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out;

  &:disabled {
    color: ${Rtheme.colors.fontColorDisabled};
  }

  &:focus {
    color: ${Rtheme.colors.fontColorFocus};
    border-color: ${Rtheme.colors.borderColorFocus};
    outline: 0;
  }
`;

export function TextInput({ id, meta, ...restProps }) {
  return (
    <>
      <Input id={id} {...restProps.input} {...restProps} />
      {meta.touched && (meta.error || meta.warning) && (
        <ErrorUI meta={meta} {...generateTag(restProps, 'error')} />
      )}
    </>
  );
}

TextInput.defaultProps = {
  id: undefined,
  placeholder: 'Input',
  borderRadius: '5px',
  height: '50px',
  meta: { error: undefined, touched: undefined },
};

TextInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
